import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import api from "../../../services/api";
import { Toaster } from "react-hot-toast";
import Page from "../../../layouts/Page";
import InvoiceList from "../components/InvoiceList";
import { Invoice } from "../types";
import { useAuth } from "../../../context/AuthContext";

const MyInvoices: React.FC = () => {
  const [invoices, setInvoices] = useState<{ data: Invoice[] }>({ data: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get(
          `/invoices/${currentUser.stripe_customer_id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setInvoices(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, [currentUser.stripe_customer_id]);

  return (
    <Page>
      <Helmet>
        <title>Mes factures | Fideliz</title>
      </Helmet>
      <Toaster />
      <div className="mt-6 border-t-2 border-l-neutral-800">
        <div className="flex justify-between items-center mb-8 px-8 py-6 mt-6 bg-black rounded-xl">
          <div className="">
            <h2 className="text-xl font-bold text-white">Mes factures&nbsp;</h2>
            <p className="text-white text-xs font-semibold mt-2">
              Liste des factures de votre boutique
            </p>
          </div>
        </div>

        {loading ? (
          <p className="font-bold mb-6 text-center mt-8">Chargement...</p>
        ) : (
          <InvoiceList invoices={invoices.data} />
        )}
      </div>
    </Page>
  );
};

export default MyInvoices;
