import React, { useState, useEffect } from "react";
import { capitalizeFirstLetter } from "../../common/utils/index";
import api from "../../services/api";
import { useAuth } from "../../context/AuthContext";
import { Toaster } from "react-hot-toast";
import Page from "../../layouts/Page";
import { FaSearch, FaTimes } from "react-icons/fa";
import { Helmet } from "react-helmet";

const MyCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [editableCa, setEditableCa] = useState(null);
  const [newCa, setNewCa] = useState("");
  const { currentUser } = useAuth();
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isSeller = currentUser.seller_type === "seller";

  const fetchCustomers = async (page) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await api.get(
        `/opticians/clients?page=${page}&limit=15&approved=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data.clients);
      const clients = response.data.clients;
      const approvedCustomers = clients.rows.filter(
        (customer) =>
          customer.status === "approved" || customer.status === "rejected"
      );
      const livredCustomers = clients.rows.filter(
        (customer) => customer.status === "delivered"
      );
      approvedCustomers.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
      livredCustomers.sort(
        (a, b) => new Date(b.updated_at) - new Date(a.updated_at)
      );
      const finalCustomers = isSeller ? livredCustomers : approvedCustomers;
      setCustomers(finalCustomers);
      setFilteredCustomers(finalCustomers);
      setCustomFields(response.data.customFields);
      setTotalPages(clients.lastPage);
    } catch (error) {
      setError("Failed to fetch customers");
      console.error("Failed to fetch customers", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers(page);
  }, [page]);

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handleCaInput = (e) => {
    // Récupérer la position du curseur
    const selection = window.getSelection();
    const range = selection.getRangeAt(0);
    const startOffset = range.startOffset;

    // Mettre à jour l'état `newCa` avec la nouvelle valeur sans perdre la position du curseur
    setNewCa(e.target.textContent);

    // Utiliser setTimeout pour restaurer la position du curseur après l'update d'état
    setTimeout(() => {
      const node = e.target.firstChild;

      if (node) {
        // Si le noeud existe, restaurer la position du curseur
        range.setStart(node, Math.min(startOffset, node.length)); // Assurer qu'on ne dépasse pas la longueur
        range.setEnd(node, Math.min(startOffset, node.length));
      }
      selection.removeAllRanges();
      selection.addRange(range);
    }, 0);
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (query) {
      const filtered = customers.filter(
        (client) =>
          client.first_name.toLowerCase().includes(query) ||
          client.last_name.toLowerCase().includes(query)
      );
      setFilteredCustomers(filtered);
    } else {
      setFilteredCustomers(customers);
    }
  };

  const handleCaClick = (customerId, currentCa) => {
    setEditableCa(customerId);
    setNewCa(currentCa);
  };

  const handleCaChange = (e) => {
    setNewCa(e.target.value);
  };

  const handleCaSave = async (customerId) => {
    try {
      const token = localStorage.getItem("token");
      await api.post(
        `/clients/change-ca/${customerId}`,
        { money_generated: newCa },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchCustomers(page);
      setEditableCa(null);
    } catch (error) {
      console.error("Failed to update CA", error);
      setError("Failed to update CA");
    }
  };

  return (
    <Page>
      <Helmet>
        <title>{isSeller ? "Mes ventes" : "Mes clients"} | Fideliz</title>
      </Helmet>
      <Toaster />
      <div className="mt-6 border-t-2 border-l-neutral-800">
        <div className="flex justify-between items-center mb-8 px-8 py-6 mt-6 bg-black rounded-xl">
          <div className="">
            <h2 className="text-xl font-bold text-white">
              {isSeller ? "Mes ventes" : "Mes clients"}&nbsp;</h2>
            <p className="text-white text-xs font-semibold mt-2">
              L'ensemble de tes ventes finalisées
            </p>
          </div>
        </div>

        <div className="w-full flex rounded-xl flex-wrap justify-center bg-white">
          <div className="w-full flex flex-col md:flex-row justify-between items-center px-8 py-4">
            <h2 className="font-semibold text-xl mb-4 md:mb-0">Clients</h2>
            <div className="relative flex items-center">
              <FaSearch className="absolute ml-4 text-gray-500" />
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearch}
                placeholder="Rechercher un client"
                className="pl-10 pr-4 py-2 text-sm px-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
            </div>
          </div>
          <div
            className="w-full bg-white rounded p-3 my-2 flex flex-col md:flex-row md:justify-between md:items-center md:px-8"
            style={{ fontSize: "0.8rem" }}
          >
            <div className="font-bold mb-2 md:mb-0">Date</div>
            <div className="md:w-1/12 font-bold mb-2 md:mb-0">Nom</div>
            <div className="md:w-1/12 font-bold mb-2 md:mb-0">Prénom</div>
            <div className="md:w-1/12 font-bold mb-2 md:mb-0">Tél</div>
            {!isSeller && customFields.map((field) => {
              if (field.name === "delay") {
                if (currentUser.sector === 'OPTIQUE_DOM') {
                  return (
                    <div className="md:w-1/12 font-bold mb-2 md:mb-0 text-xs">Date d'appel</div>
                  )
                }
                return (
                  <div className="md:w-1/12 font-bold mb-2 md:mb-0 text-xs">Visite</div>
                );
              }
              return (
                <div
                  key={field.name}
                  className="md:w-1/12 text-xs font-bold mb-2 md:mb-0"
                >
                  {field.dashboard_title}
                </div>
              );
            })}
            <div className="md:w-1/12 font-bold mb-2 md:mb-0 text-xs cursor-pointer flex items-center">
              Parrain
            </div>
            {isSeller && (
              <div className="md:w-1/12 font-bold mb-2 md:mb-0 text-xs cursor-pointer flex items-center">
                Produits
              </div>
            )}

            <div className="md:w-1/12 font-bold mb-2 md:mb-0 text-center ">
              CA
            </div>
          </div>
          {filteredCustomers.map((customer) => (
            <div
              key={customer.id}
              className="bg-white rounded border-t py-5 mx-8 w-full flex flex-col md:flex-row md:justify-between md:items-center"
              style={{ fontSize: "0.8rem" }}
            >
              <div className="mb-2 md:mb-0 font-semibold text-xs">
                {new Date(customer.created_at).toLocaleDateString("fr-FR", {
                  day: "2-digit",
                  month: "2-digit",
                })}
              </div>
              <div className="md:w-1/12 mb-2 md:mb-0 font-bold text-xs">
                {capitalizeFirstLetter(customer.last_name)}
              </div>
              <div className="md:w-1/12 mb-2 md:mb-0 font-bold text-xs">
                {capitalizeFirstLetter(customer.first_name)}
              </div>
              <div className="md:w-1/12 mb-2 md:mb-0 font-semibold text-xs">
                {customer.phone}
              </div>

              {!isSeller && customFields.map((field) => {
                const value = customer[field.name];
                return (
                  <div
                    key={field.name}
                    className="md:w-1/12 mb-2 md:mb-0 font-semibold text-xs"
                  >
                    {value === "Oui"
                      ? "✔️"
                      : value === "Non"
                        ? "❌"
                        : value || "Non renseigné"}
                  </div>
                );
              })}

              <div className="md:w-1/12 mb-2 md:mb-0 font-semibold text-xs">
                {customer.sponsor_name}
              </div>
              {isSeller && (
                <div className="md:w-1/12                 mb-2 text-center md:mb-0 font-semibold text-xs">
                  <div
                    onClick={() => setIsModalOpen(true)}
                    className="bg-orange-200 cursor-pointer text-orange-600 flex items-center justify-center px-2 py-1 rounded-lg"
                  >
                    <p className="font-semibold text-xs">Afficher</p>
                  </div>
                </div>
              )}

              {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                  <div className="bg-white p-4 rounded-lg w-11/12 md:w-2/3 lg:w-1/2">
                    <div className="flex justify-between items-center mb-4">
                      <h2 className="text-xl font-semibold">
                        Fiche de vente de {customer.first_name}{" "}
                        {customer.last_name}
                      </h2>
                      <button
                        onClick={() => setIsModalOpen(false)}
                        className="text-gray-500 hover:text-gray-700"
                      >
                        <FaTimes />
                      </button>
                    </div>

                    {customer.articles && customer.articles.length > 0 && (
                      <div className="mb-4">
                        <h3 className="text-sm font-bold mb-2">Articles</h3>
                        {customer.articles.map((article, index) => (
                          <div
                            key={index}
                            className="flex flex-col items-center md:flex-row mb-2"
                          >
                            <span>{article.title} -</span>
                            <span className="ml-2 text-xs font-bold">
                              x{article.quantity}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}

                    {customFields.map((field) => {
                      const clientFieldValue = customer[field.name];
                      if (!clientFieldValue) return null;

                      return (
                        <div key={field.name} className="mb-4">
                          <div className="text-sm font-bold mb-2">
                            {field.dashboard_title}
                          </div>
                          <div className="text-sm font-light">
                            {clientFieldValue}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              <div className="md:w-1/12 mb-2 md:mb-0 font-semibold text-xs">
                {editableCa === customer.id ? (
                  <div
                    contentEditable
                    suppressContentEditableWarning
                    onInput={handleCaInput}
                    onBlur={() => handleCaSave(customer.id)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleCaSave(customer.id);
                      }
                    }}
                    className="bg-white border border-gray-300 px-2 py-1 rounded-lg text-center"
                    style={{ minWidth: "50px" }} // pour garder une taille minimale
                  >
                    {newCa || ""}
                  </div>
                ) : (
                  <div
                    className={`${customer.status === "rejected"
                      ? "bg-red-200 text-red-600"
                      : "bg-green-200 text-green-600"
                      } flex items-center justify-center px-2 py-1 rounded-lg ${!isSeller && "cursor-pointer"
                      }`}
                    onClick={() => {
                      if (isSeller) return;
                      customer.status !== "rejected" &&
                        handleCaClick(customer.id, customer.money_generated);
                    }}
                  >
                    <p className="font-semibold">
                      {customer.status === "rejected"
                        ? "Rejeté"
                        : customer.money_generated
                          ? `${customer.money_generated}€`
                          : "Non renseigné"}
                    </p>
                  </div>
                )}
              </div>
            </div>
          ))}
          <div className="flex flex-col">
            {filteredCustomers.length === 0 && (
              <p className="font-bold mb-6 text-center mt-8">
                Aucun client pour le moment
              </p>
            )}
            <div className="flex justify-center my-6">
              <button
                onClick={() => handlePreviousPage()}
                disabled={page === 1}
                className="px-4 py-2 mx-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50 text-xs"
              >
                Page précédente
              </button>
              <button
                onClick={() => handleNextPage()}
                disabled={page === totalPages}
                className="px-4 py-2 mx-2 bg-gray-200 text-gray-800 rounded disabled:opacity-50 text-xs"
              >
                Page suivante
              </button>
            </div>
          </div>
        </div>
        {error && <p className="font-bold mb-4 text-center mt-8">{error}</p>}
      </div>
    </Page>
  );
};

export default MyCustomers;