import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useAuth } from "../../../context/AuthContext";
import { toast, Toaster } from "react-hot-toast";
import Page from "../../../layouts/Page";
import { parseISO, format, differenceInCalendarMonths } from "date-fns";
import { FaSearch } from "react-icons/fa";
import { Helmet } from "react-helmet";

const Sponsors = () => {
  const [sponsors, setSponsors] = useState([]);
  const [filteredSponsors, setFilteredSponsors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [totalParrainsMois, setTotalParrainsMois] = useState(0);
  const [objectifMensuel, setObjectifMensuel] = useState(0);

  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const isSeller = currentUser?.seller_type === "seller";

  const fetchSponsors = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await api.get("/opticians/sponsors", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sponsorsData = response.data.sponsors || [];
      const monthlyTotal = response.data.monthlyTotal || 0;
      const sorted = [...sponsorsData].sort((a, b) => {
        const dateA = new Date(a.createdAt);
        const dateB = new Date(b.createdAt);
        return dateA - dateB;
      });
      setSponsors(sorted);
      setFilteredSponsors(sorted);
      setTotalParrainsMois(monthlyTotal);
    } catch (err) {
      setError("Failed to fetch sponsors");
      console.error("Failed to fetch sponsors", err);
    } finally {
      setLoading(false);
    }
  };

  const computeObjectifMensuel = (creationDateString) => {
    if (!creationDateString) return;
    const now = new Date();
    const creationDate = parseISO(creationDateString);
    const monthsDiff = differenceInCalendarMonths(now, creationDate);
    const objectif = monthsDiff < 4 ? 15 : 10;
    setObjectifMensuel(objectif);
  };

  useEffect(() => {
    fetchSponsors();
  }, []);

  useEffect(() => {
    if (currentUser?.created_at) {
      computeObjectifMensuel(currentUser.created_at);
    }
  }, [currentUser]);

  const askDeleteSponsor = async (id) => {
    try {
      const token = localStorage.getItem("token");
      toast
        .promise(
          api.post(
            `/opticians/sponsors/${id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          ),
          {
            loading: "Demande de suppression en cours ...",
            success: "Demande prise en compte",
            error: "Échec de la demande de suppression",
          }
        )
        .then(() => {
          fetchSponsors();
        });
    } catch (err) {
      setError("Failed to delete sponsor");
      console.error("Failed to delete sponsor", err);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    if (!query) {
      setFilteredSponsors(sponsors);
      return;
    }
    const filtered = sponsors.filter(
      (sponsor) =>
        sponsor.first_name.toLowerCase().includes(query) ||
        sponsor.last_name.toLowerCase().includes(query) ||
        sponsor.email.toLowerCase().includes(query)
    );
    setFilteredSponsors(filtered);
  };

  const getPlanLevelText = (planLevel) => {
    switch (planLevel) {
      case 1:
        return 10;
      case 2:
        return 10;
      case 3:
        return "Illimité";
      default:
        return "";
    }
  };

  const isDisabled =
    currentUser?.sponsor >= getPlanLevelText(currentUser?.plan_level) &&
    currentUser?.plan_level !== 3;

  return (
    <Page>
      <Helmet>
        <title>{isSeller ? "Mes vendeurs" : "Mes parraineurs"} | Fideliz</title>
      </Helmet>
      <Toaster />
      <div className="mt-6 border-t-2 border-l-neutral-800">
        <div className="flex flex-col lg:flex-row justify-between items-center mb-4 px-4 lg:px-8 py-6 mt-6 bg-black rounded-xl">
          <div className="mb-4 lg:mb-0">
            <h2 className="text-xl font-bold text-white">
              {isSeller ? "Mes vendeurs" : "Mes parraineurs"} &nbsp;
              {currentUser?.plan_level !== 3 ? (
                <>
                  ({currentUser?.sponsor} / {getPlanLevelText(currentUser?.plan_level)})
                </>
              ) : (
                <>({getPlanLevelText(currentUser?.plan_level)})</>
              )}
            </h2>
            <p className="text-white text-xs font-semibold mt-2">
              
              Gère tes {isSeller ? "vendeurs" : "parraineurs"} et traque leurs performances
            </p>
          </div>
          <div className="flex flex-col lg:flex-row">
            {isDisabled && (
              <button
                onClick={() => navigate("/plan")}
                className="px-6 py-3 mr-0 lg:mr-3 mb-3 lg:mb-0 text-white rounded-lg font-bold bg-blue-500 hover:bg-blue-600 cursor-pointer"
              >
                Changer d'abonnement
              </button>
            )}
            <button
              onClick={() => navigate("/sponsors/add")}
              className={`px-6 py-3 text-white text-xs rounded-lg font-bold ${isDisabled ? "bg-gray-400 cursor-not-allowed" : "bg-blue-500 hover:bg-blue-600"
                }`}
              disabled={isDisabled}
            >
              {isSeller ? "Inviter un vendeur" : "Inviter un parraineur"}
            </button>
          </div>
        </div>
        <div className="flex flex-col justify-between items-center w-full mb-8 px-4 lg:px-8 py-6 mt-4 bg-black rounded-xl">
          <div className="mb-4 lg:mb-0">
            <h2 className="text-white text-md mb-3">Objectif de parrains du mois</h2>
            <p className="text-white text-2xl text-center font-bold">
              {totalParrainsMois} / {objectifMensuel} Parrains
            </p>
          </div>

          {Number(totalParrainsMois) !== Number(objectifMensuel) && (
            <p className="text-white text-xs text-center mb-3 italic">
              Objectif à réaliser pour avoir l'abonnement du mois offert
            </p>
          )}


          {Number(totalParrainsMois) === Number(objectifMensuel) && (
            <p className="text-green-400 text-xs text-center mb-3 italic">
              Félicitations, l'abonnement est offert ce mois-ci ! <span style={{ fontStyle: "normal" }}>🎁</span> <br />
              Continuez d'ajouter plus de parrains pour de meilleurs résultats.
            </p>
          )}

          <div className="w-full bg-blue-950 rounded-full h-3 overflow-hidden">
            <div
              className="bg-blue-500 h-full"
              style={{
                width: `${objectifMensuel
                  ? Math.min((totalParrainsMois / objectifMensuel) * 100, 100)
                  : 0
                  }%`,
              }}
            />
          </div>
        </div>
        <div>
          <div className="bg-white rounded-xl w-full pt-2 px-4 lg:px-8">
            <div className="w-full flex flex-col md:flex-row justify-between items-center py-4">
              <h2 className="font-semibold text-xl mb-4 md:mb-0">Clients</h2>
              <div className="relative flex items-center">
                <FaSearch className="absolute ml-4 text-gray-500" />
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Rechercher un client"
                  className="pl-10 pr-4 py-2 text-sm px-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
              </div>
            </div>
            <div className="hidden lg:flex py-2 font-bold mb-2">
              <div className="w-1/12 text-xs text-left">Prénom/Nom</div>
              <div className="w-3/12 text-xs text-center">E-mail</div>
              <div className="w-1/12 text-xs text-center">Tél.</div>
              <div className="w-2/12 text-xs text-center">Clients parrainés</div>
              <div className="w-1/12 text-xs text-center">CA généré</div>
              <div className="w-2/12 text-xs text-center">Dernière connexion</div>
              <div className="w-2/12 text-xs text-center">Action</div>
            </div>
            {loading ? (
              <div className="flex justify-center items-center py-6">
                <div className="loader"></div>
              </div>
            ) : filteredSponsors.length > 0 ? (
              filteredSponsors.map((sponsor) => (
                <div
                  key={sponsor._id}
                  className="flex flex-col lg:flex-row py-4 border-gray-200 border-t items-start lg:items-center"
                >
                  <div className="w-full lg:w-1/12 text-xs text-left font-semibold">
                    {sponsor.first_name} {sponsor.last_name}
                  </div>
                  <div className="w-full lg:w-3/12 text-center font-normal text-xs text-gray-600">
                    {sponsor.email}
                  </div>
                  <div className="w-full lg:w-1/12 text-xs text-center font-semibold">
                    {sponsor.phone}
                  </div>
                  <div className="w-full lg:w-2/12 text-center text-xs font-semibold">
                    {sponsor.totalReferred || 0}
                  </div>
                  <div className="w-full lg:w-1/12 text-center text-xs font-semibold">
                    {sponsor.totalCA || 0} €
                  </div>
                  <div className="w-full lg:w-2/12 text-xs text-center font-semibold">
                    {format(parseISO(sponsor.updatedAt), "dd/MM/yyyy 'à' HH:mm")}
                  </div>
                  <div className="w-full lg:w-2/12 flex justify-between text-xs lg:justify-start">
                    {sponsor.ask_delete ? (
                      <button
                        className="bg-gray-100 text-gray-600 font-semibold text-sm py-2 px-4 rounded w-full"
                        disabled
                      >
                        En attente de Fideliz
                      </button>
                    ) : (
                      <button
                        className="bg-red-100 text-red-600 text-xs font-semibold text-sm py-2 px-4 rounded w-full"
                        onClick={() => askDeleteSponsor(sponsor.id)}
                      >
                        Demander une suppression
                      </button>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="py-8">
                <p className="text-center font-semibold my-4 mb-4">
                  Aucun parraineur n'a accepté votre invitation pour le moment
                </p>
              </div>
            )}
            {isDisabled && (
              <p className="font-semibold text-lg text-center py-8">
                ⚠️ Vous devez changer d'abonnement pour inviter davantage de parraineurs.
              </p>
            )}
          </div>
        </div>
        {error && <p className="font-bold mb-4 text-center mt-8">{error}</p>}
      </div>
    </Page>
  );
};

export default Sponsors;