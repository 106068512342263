import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import api from "../../../services/api";
import toast, { Toaster } from "react-hot-toast";
import Page from "../../../layouts/Page";
import VoucherList from "../components/VoucherList";
import { capitalizeFirstLetter } from "../../../common/utils";
import { Voucher } from "../types";

const Vouchers: React.FC = () => {
  const [vouchers, setVouchers] = useState<Voucher[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchVouchers = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await api.get("/vouchers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setVouchers(response.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    };

    fetchVouchers();
  }, []);

  const handleAccept = async (voucherId: number) => {
    try {
      const token = localStorage.getItem("token");
      const response = await api.put(
        `/vouchers/${voucherId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Bon d'achat accepté avec succès.");
        setVouchers(
          vouchers.map((voucher) =>
            voucher.id === voucherId ? { ...voucher, is_used: true } : voucher
          )
        );
      } else {
        toast.error(
          "Une erreur s'est produite lors de la mise à jour du bon d'achat."
        );
      }
    } catch (err) {
      toast.error(
        "Une erreur s'est produite lors de la mise à jour du bon d'achat."
      );
    }
  };

  return (
    <Page>
      <Helmet>
        <title>Mes bons d'achats | Fideliz</title>
      </Helmet>
      <Toaster />
      <div className="mt-6 border-t-2 border-l-neutral-800">
        <div className="flex justify-between items-center mb-8 px-8 py-6 mt-6 bg-black rounded-xl">
          <div className="">
            <h2 className="text-xl font-bold text-white">
              Mes bons d'achats&nbsp;
            </h2>
            <p className="text-white text-xs font-semibold mt-2">
              Liste des bons d'achats de votre boutique
            </p>
          </div>
        </div>

        {loading ? (
          <p className="font-bold mb-6 text-center mt-8">Chargement...</p>
        ) : (
          <VoucherList
            vouchers={vouchers}
            handleAccept={handleAccept}
            capitalizeFirstLetter={capitalizeFirstLetter}
          />
        )}
      </div>
    </Page>
  );
};

export default Vouchers;
