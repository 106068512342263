import React from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import authService from "../services/authService";
import Logo from "../../../common/assets/blue-logo.svg";

const ForgotPassword: React.FC = () => {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();

    const onSubmit = async (data: any) => {
        try {
            await authService.forgetPassword(data.email);
            toast.success("Un lien de réinitialisation a été envoyé à votre adresse e-mail.");
            reset();
        } catch (error) {
            toast.error("Erreur lors de l'envoi du lien de réinitialisation.");
        }
    };

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <div className="w-full flex min-h-screen overflow-hidden">
                {/* Desktop version of the left section */}
                <div
                    className="hidden lg:flex p-16 flex-col justify-between items-start"
                    style={{
                        width: "35%",
                        backgroundColor: "#cadcfa",
                        height: "100vh",
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    <img src={Logo} style={{ marginLeft: 15 }} alt="Logo" className="h-8" />
                    <img
                        src={require("../../../common/assets/signup-illu.png")}
                        style={{
                            marginLeft: "auto",
                            position: "absolute",
                            width: "90%",
                            bottom: 0,
                            right: 0,
                        }}
                        alt="Illustration"
                    />
                </div>

                {/* Mobile and tablet version */}
                <div
                    className="flex flex-col w-full lg:pr-72 lg:w-2/3 overflow-y-auto px-6 py-8 sm:px-8 lg:px-28 lg:py-16"
                    style={{ height: "100vh" }}
                >
                    <div className="lg:hidden mb-6 flex justify-center">
                        <img src={Logo} alt="Logo" className="h-8" />
                    </div>

                    <h2 className="text-2xl lg:text-3xl font-bold mb-6 lg:mb-9 text-center lg:text-left">
                        Mot de passe oublié
                    </h2>

                    <div
                        className="flex justify-center items-center mb-6 lg:mb-10 py-4 lg:py-5 rounded-lg"
                        style={{ backgroundColor: "#FAF9F8" }}
                    >
                        <p className="text-sm">
                            Pas encore de compte ?{" "}
                            <a 
                                href="https://www.fideliz.fr/book-a-demo" 
                                className="font-bold underline cursor-pointer"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Contactez-nous
                            </a>
                        </p>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Email
                            </label>
                            <input
                                type="email"
                                {...register("email", {
                                    required: "L'email est requis",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Adresse email invalide",
                                    },
                                })}
                                className={`w-full px-3 py-3 border rounded ${
                                    errors.email ? "border-red-500" : "border-gray-300"
                                }`}
                            />
                            {errors.email && (
                                <p className="mt-1 text-sm text-red-600">
                                    {errors.email.message as string}
                                </p>
                            )}
                        </div>

                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white py-3 px-4 font-bold rounded-lg hover:bg-blue-700 transition-colors"
                        >
                            Réinitialiser mon mot de passe
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;
